import { Outlet } from "react-router-dom";
import { experimentalStyled } from "@material-ui/core";

const FullScreenLayoutRoot = experimentalStyled("div")(({ theme, bgColor }) => ({
  backgroundColor: {bgColor},
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const FullScreenLayoutWrapper = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const FullScreenLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const FullScreenLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const FullScreenLayout = () => (
  <FullScreenLayoutRoot>
    <FullScreenLayoutWrapper>
      <FullScreenLayoutContainer>
        <FullScreenLayoutContent>
          <Outlet />
        </FullScreenLayoutContent>
      </FullScreenLayoutContainer>
    </FullScreenLayoutWrapper>
  </FullScreenLayoutRoot>
);

export default FullScreenLayout;
