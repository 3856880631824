import { ThemeProvider } from "@material-ui/core";
import routes from "./routes";
import { useRoutes } from "react-router-dom";
import GlobalStyles from "./GlobalStyles";
import theme from "./theme";

const App = () => {
  const routing = useRoutes(routes);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
