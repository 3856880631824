import { Helmet } from "react-helmet";
import { Grid } from "@material-ui/core";
import QuestComponent from "./QuestComponent";
import { useState } from "react";

const Questionnaire = () => {
  const [bgColor,setBgColor] = useState("#f3f5fb")
  console.log(bgColor)
  return (
    <div
      style={{ display: "flex", justifyContent: "center", overflowX: "hidden", backgroundColor:`${bgColor}`, minHeight:"100vh"}}
    >
      <Helmet>
        <title>Landing Page | Aksel</title>
      </Helmet>
      {/* TODO: kalau mau dipaksa 375px */}
      {/* <Container> */}
      <Grid container style={{ paddingTop: 0, maxWidth: "480px" }}>
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            backgroundColor: `${bgColor}`,
            marginTop: 10,
            marginBottom: 10
          }}
        >
          <QuestComponent bgColor={bgColor} setBgColor={setBgColor} />
        </Grid>
      </Grid>
      {/* </Container> */}
    </div>
  );
};

export default Questionnaire;
