import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        backgroundColor: "#f4f6f8",
        height: "100%",
        width: "100%",
      },
      a: {
        textDecoration: "none",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ".MuiContainer-root": {
        paddingLeft: "24px !important",
        paddingRight: "24px !important",
        maxWidth: "480px !important",
      },
      ".css-1dztziv": {
        bottom: "63px !important",
      },
      ".css-um7ixe": {
        bottom: "125px !important",
      },
      ".css-lpr4eb-ReactDOMBlockingRoot": {
        bottom: "63px !important",
      },
      ".css-161wdqb-ReactDOMBlockingRoot": {
        bottom: "122px !important",
      },
      ".customCanvasCss": {
        height: "463px",
        backgroundColor: "#fff",
        borderRadius: "16px",
        boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.08)",
        marginBottom: "16px",
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
