const questTemplate =  [
    {
      id: "1",
      question: "Apakah kamu tahu cara mendapatkan informasi mengenai perusahaan atau industri untuk persiapan karir kamu?",
      description: "Pilih salah satu untuk kami bantu mencari kebutuhanmu!",
      values: [
        {
          id: "1-1",
          value: "Ya",
          output: [
            {
              id: "1-11",
              value: "",
            },
          ],
        },
        {
          id: "1-2",
          value: "Tidak",
          output: [
            {
              id: "1-21",
              value: "Bootcamp",
            },
          ],
        },
        
      ],
    },
    {
      id: "2",
      question: "Apakah kamu tahu cara mendapatkan informasi mengenai posisi impian di perusahaan/ industri yang kamu tuju?",
      values: [
        {
          id: "2-1",
          value: "Ya",
          output: [
            {
              id: "2-11",
              value: "",
            },
          ],
        },
        {
          id: "2-2",
          value: "Tidak",
          output: [
            {
              id: "2-21",
              value: "Bootcamp",
            },
          ],
        },
        
      ],
    },
    {
      id: "3",
      question: "Apakah kamu tahu sifat dari pekerjaan di Industri yang kamu mau?",
      values: [
        {
          id: "3-1",
          value: "Ya",
          output: [
            {
              id: "3-11",
              value: "",
            },
          ],
        },
        {
          id: "3-2",
          value: "Tidak",
          output: [
            {
              id: "3-21",
              value: "Bootcamp",
            },
          ],
        },
        
      ],
    },
    {
      id: "4",
      question: "Apakah kamu mengetahui pengetahuan dan keahlian yang dibutuhkan oleh industri yang kamu impikan?",
      values: [
        {
          id: "4-1",
          value: "Ya",
          output: [
            {
              id: "4-11",
              value: "",
            },
          ],
        },
        {
          id: "4-2",
          value: "Tidak",
          output: [
            {
              id: "4-21",
              value: "Bootcamp",
            },
          ],
        },
        
      ],
    },
    {
      id: "5",
      question: "Apakah kamu tahu tips and trick untuk mendapat bekerja di industri yang kamu mau?",
      values: [
        {
          id: "5-1",
          value: "Ya",
          output: [
            {
              id: "5-11",
              value: "",
            },
          ],
        },
        {
          id: "5-2",
          value: "Tidak",
          output: [
            {
              id: "5-21",
              value: "Bootcamp",
            },
          ],
        },
        
      ],
    },
    {
      id: "6",
      question: "Apakah kamu mengetahui kelebihan dan kekurangan bekerja di industri yang kamu mau?",
      values: [
        {
          id: "6-1",
          value: "Ya",
          output: [
            {
              id: "6-11",
              value: "",
            },
          ],
        },
        {
          id: "6-2",
          value: "Tidak",
          output: [
            {
              id: "6-21",
              value: "Bootcamp",
            },
          ],
        },
        
      ],
    },
    {
      id: "7",
      question: "Apakah kamu mendesain CV secara manual?",
      values: [
        {
          id: "7-1",
          value: "Ya",
          output: [
            {
              id: "7-11",
              value: "",
            },
          ],
        },
        {
          id: "7-2",
          value: "Tidak",
          output: [
            {
              id: "7-21",
              value: "CV Maker",
            },
          ],
        },
        
      ],
    },
    {
      id: "8",
      question: "Apakah kamu tahu cara mendapatkan design CV dengan standar profesional?",
      values: [
        {
          id: "8-1",
          value: "Ya",
          output: [
            {
              id: "8-11",
              value: "",
            },
          ],
        },
        {
          id: "8-2",
          value: "Tidak",
          output: [
            {
              id: "8-21",
              value: "CV Maker",
            },
          ],
        },
        
      ],
    },
    {
      id: "9",
      question: "Apakah kamu pernah mendengar tentang Applicant Tracking Systems (ATS) yang sering digunakan oleh rekruter di perusahaan?",
      values: [
        {
          id: "9-1",
          value: "Ya",
          output: [
            {
              id: "9-11",
              value: "",
            },
          ],
        },
        {
          id: "9-2",
          value: "Tidak",
          output: [
            {
              id: "9-21",
              value: "CV Maker",
            },
          ],
        },
        
      ],
    },
    {
      id: "10",
      question: "Apakah kamu tahu cara kerja Applicant Tracking Systems (ATS) dalam menyortir CV kandidat yang masuk?",
      values: [
        {
          id: "10-1",
          value: "Ya",
          output: [
            {
              id: "10-11",
              value: "",
            },
          ],
        },
        {
          id: "10-2",
          value: "Tidak",
          output: [
            {
              id: "10-21",
              value: "CV Maker",
            },
          ],
        },
        
      ],
    },
    {
      id: "11",
      question: "Apakah kamu tahu masalah performatan dalam Applicant Tracking System (ATS)?",
      values: [
        {
          id: "11-1",
          value: "Ya",
          output: [
            {
              id: "11-11",
              value: "",
            },
          ],
        },
        {
          id: "11-2",
          value: "Tidak",
          output: [
            {
              id: "11-21",
              value: "CV Maker",
            },
          ],
        },
        
      ],
    },
    {
      id: "12",
      question: "Apakah kamu tahu komponen-komponen penting yang harus dicantumkan dalam CV?",
      values: [
        {
          id: "12-1",
          value: "Ya",
          output: [
            {
              id: "12-11",
              value: "",
            },
          ],
        },
        {
          id: "12-2",
          value: "Tidak",
          output: [
            {
              id: "12-21",
              value: "CV Maker",
            },
          ],
        },
        
      ],
    },
    {
      id: "13",
      question: "Apakah CV kamu dituis dalam 1-2 halaman?",
      values: [
        {
          id: "13-1",
          value: "Ya",
          output: [
            {
              id: "13-11",
              value: "",
            },
          ],
        },
        {
          id: "13-2",
          value: "Tidak",
          output: [
            {
              id: "13-21",
              value: "CV Review",
            },
          ],
        },
        
      ],
    },
    {
      id: "14",
      question: "Apakah kamu yakin dengan penggunaan kata, ejaan, dan tata bahasa dalam CV kamu?",
      values: [
        {
          id: "14-1",
          value: "Ya",
          output: [
            {
              id: "14-11",
              value: "",
            },
          ],
        },
        {
          id: "14-2",
          value: "Tidak",
          output: [
            {
              id: "14-21",
              value: "CV Review",
            },
          ],
        },
        
      ],
    },
    {
      id: "15",
      question: "Apakah kamu menuliskan profil singkat berisi tentang short introduction, interests, passions, career objective, dan activities dalam CV?",
      values: [
        {
          id: "15-1",
          value: "Ya",
          output: [
            {
              id: "15-11",
              value: "",
            },
          ],
        },
        {
          id: "15-2",
          value: "Tidak",
          output: [
            {
              id: "15-21",
              value: "CV Review",
            },
          ],
        },
        
      ],
    },
    {
      id: "16",
      question: "Apakah kamu mencantumkan soft skills DAN hard skills yang kamu punya dalam CV?",
      values: [
        {
          id: "16-1",
          value: "Ya",
          output: [
            {
              id: "16-11",
              value: "",
            },
          ],
        },
        {
          id: "16-2",
          value: "Tidak",
          output: [
            {
              id: "16-21",
              value: "CV Review",
            },
          ],
        },
        
      ],
    },
    {
      id: "17",
      question: "Apakah kamu menuliskan informasi pengalaman kamu seperti deskripsi pekerjaan beserta hasil dari pekerjaan kamu dalam CV?",
      values: [
        {
          id: "17-1",
          value: "Ya",
          output: [
            {
              id: "17-11",
              value: "",
            },
          ],
        },
        {
          id: "17-2",
          value: "Tidak",
          output: [
            {
              id: "17-21",
              value: "CV Review",
            },
          ],
        },
        
      ],
    },
    {
      id: "18",
      question: "Apakah kamu menuliskan pengalaman yang sesuai dengan posisi kerja yang akan kamu lamar?",
      values: [
        {
          id: "18-1",
          value: "Ya",
          output: [
            {
              id: "18-11",
              value: "",
            },
          ],
        },
        {
          id: "18-2",
          value: "Tidak",
          output: [
            {
              id: "18-21",
              value: "CV Review",
            },
          ],
        },
        
      ],
    },
    {
      id: "19",
      question: "Apakah kamu sudah yakin dengan aspirasi karier yang kamu punya?",
      values: [
        {
          id: "19-1",
          value: "Ya",
          output: [
            {
              id: "19-11",
              value: "",
            },
          ],
        },
        {
          id: "19-2",
          value: "Tidak",
          output: [
            {
              id: "19-21",
              value: "Mentoring",
            },
          ],
        },
        
      ],
    },
    {
      id: "20",
      question: "Apakah kamu sudah memiliki gambaran nyata mengenai aspirasi karier kamu?",
      values: [
        {
          id: "20-1",
          value: "Ya",
          output: [
            {
              id: "20-11",
              value: "",
            },
          ],
        },
        {
          id: "20-2",
          value: "Tidak",
          output: [
            {
              id: "20-21",
              value: "Mentoring",
            },
          ],
        },
        
      ],
    },
    {
      id: "21",
      question: "Apakah kamu mengetahui keahlian, kemampuan, dan pengalaman apa saja yang dibutuhkan untuk melamar sebuah pekerjaan?",
      values: [
        {
          id: "21-1",
          value: "Ya",
          output: [
            {
              id: "21-11",
              value: "",
            },
          ],
        },
        {
          id: "21-2",
          value: "Tidak",
          output: [
            {
              id: "21-21",
              value: "Mentoring",
            },
          ],
        },
        
      ],
    },
    {
      id: "22",
      question: "Apakah kamu tahu tentang kelebihan dan kekurangan yang dimiliki diri sendiri?",
      values: [
        {
          id: "22-1",
          value: "Ya",
          output: [
            {
              id: "22-11",
              value: "",
            },
          ],
        },
        {
          id: "22-2",
          value: "Tidak",
          output: [
            {
              id: "22-21",
              value: "Mentoring",
            },
          ],
        },
        
      ],
    },
    {
      id: "23",
      question: "Apakah kamu memiliki gambaran nyata mengenai dunia kerja?",
      values: [
        {
          id: "23-1",
          value: "Ya",
          output: [
            {
              id: "23-11",
              value: "",
            },
          ],
        },
        {
          id: "23-2",
          value: "Tidak",
          output: [
            {
              id: "23-21",
              value: "Mentoring",
            },
          ],
        },
        
      ],
    },
    {
      id: "24",
      question: "Apakah kamu mengetahui cara mempersiapkan CV dan wawancara untuk persiapan kerja?",
      values: [
        {
          id: "24-1",
          value: "Ya",
          output: [
            {
              id: "24-11",
              value: "",
            },
          ],
        },
        {
          id: "24-2",
          value: "Tidak",
          output: [
            {
              id: "24-21",
              value: "Mentoring",
            },
          ],
        },
        
      ],
    },
  ];
  
  export default questTemplate; 
  