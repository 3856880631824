import { Button, Typography } from "@material-ui/core";

const CustomButton = ({
  title,
  variant = "contained",
  color = "primary",
  children,
  smallButton,
  disabled,
  ...props
}) => {
  let buttonStyle = {
    backgroundColor: "#1D2D5E",
    color: "#fff",
    borderRadius: "8px",
    boxShadow: "0 0 0 0 rgb(0 0 0 / 31%), 0 0 0 0 rgb(0 0 0 / 25%)",
  };

  if (color === "secondary") {
    buttonStyle["backgroundColor"] = "#FACD11";
    buttonStyle["color"] = "#1D2D5E";
  } else if (color === "grey") {
    buttonStyle["backgroundColor"] = "#1D2D5E";
    buttonStyle["opacity"] = 0.3;
  } else if (color === "tertiary") {
    buttonStyle["backgroundColor"] = "#EFF9FD";
    buttonStyle["color"] = "#1D2D5E";
    buttonStyle["borderColor"] = "#5BC0EB";
  }

  if (variant === "outlined") {
    buttonStyle["backgroundColor"] = "#FFF";
    buttonStyle["borderWidth"] = "2px";
  }

  // end of setting style
  if (disabled) {
    buttonStyle["opacity"] = 0.3;
  }

  return (
    <Button
      style={buttonStyle}
      variant={variant}
      disabled={disabled}
      {...props}
    >
      <Typography
        variant={smallButton ? "smallButton" : "button"}
        sx={{ textTransform: "none", padding: "5px 16px" }}
      >
        {children}
      </Typography>
    </Button>
  );
};

export default CustomButton;
