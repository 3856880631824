import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Grow,
  Container,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReplayIcon from "@material-ui/icons/Replay";
import questions from "./questTemplate";
import CustomButton from "./button";

let arr = [0,0,0,0]
let arrayResult = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

const Quest = ({bgColor,setBgColor}) => {
  const [active, setActive] = useState(0);
  const [selected, setSelected] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [animate, setAnimate] = useState(true);
  const [lastQuestion, setLastQuestion] = useState(false);
  const [textResult, setTextResult] = useState(null);

  const handleSubmit = (value, index) => {
    const isLast = index + 1 === questions.length;
    Promise.all([!isLast && setAnimate(false)]).then((_) => {
      if(value.value==="Tidak"){
        arrayResult[index]=1;
      } else if(value.value==="Ya"){
        arrayResult[index]=0;
      }
      selected[index] = value.id;
      setSelected(selected);
      setIsSelect(!isSelect);
      setTimeout(() => {
        if (!isLast) {
          setActive(index + 1);
        }
        setAnimate(true);
      }, 200);
    });

    // final result
    arr = [0,0,0,0];
    for(let i=0;i<6;i++){
      arr[0] += arrayResult[i];
    }
    for(let i=6;i<12;i++){
      arr[1] += arrayResult[i];
    }
    for(let i=12;i<18;i++){
      arr[2] += arrayResult[i];
    }
    for(let i=18;i<24;i++){
      arr[3] += arrayResult[i];
    }
    let results;
    switch(arr.indexOf(Math.max(...arr))){
      case 0:
        results = "Bootcamp";
        break
      case 1:
        results = "CV Maker";
        break;
      case 2:
        results = "CV Review";
        break;
      case 3:
        results = "Mentoring";
        break;
      default:
        results = null;
    }
    if (isLast) {
      console.log(active);
      setBgColor("#1d2d5e");
      setLastQuestion(!lastQuestion);
      setTextResult(results);
    }
  };

  const handleRetake = () => {
    setActive(0);
    setLastQuestion(false);
    setSelected([]);
    setTextResult(null);
    setBgColor("#f3f5fb");
  };

  const handleArrow = async (flag) => {
    if (flag === "back") {
      setActive(active - 1);
    } else {
      setActive(active + 1);
    }
  };


  return (
        <>
          <Container
            sx={
              lastQuestion || textResult
                ? { backgroundColor: "#1D2D5E" }
                : { display: "none" }
            }
          >
            <Typography
              variant="p"
              sx={{ padding: "66px 0 8px", color: "#EFF9FD" }}
            >
              Saat ini kamu membutuhkan jasa
            </Typography>
            <Typography variant="header" style={{ color: "#EFF9FD" }}>
              {textResult}
            </Typography>
            <a target="_blank" href="https://aksel.co.id">
              <CustomButton
                sx={{
                  margin: "24px 0 16px",
                  paddingLeft: "55px",
                  paddingRight: "55px",
                }}
                variant="contained"
                color="secondary"
              >
                Pergi ke web aksel.co.id
              </CustomButton>
            </a>
            <CustomButton
              variant="contained"
              color="secondary"
              sx={{ marginBottom: "66px", backgroundColor: "white !important" }}
              onClick={handleRetake}
              startIcon={<ReplayIcon />}
            >
              Retake Assessment
            </CustomButton>
          </Container>
          <Container
            sx={lastQuestion || textResult ? { display: "none" } : null}
          >
            <Grid
              container
              spacing={1}
              style={{
                justifyContent: "center",
                marginTop: "3px",
                paddingBottom: "32px",
              }}
            >
              <Grid item xs={6} sx={active === 0 ? { display: "none" } : null}>
                <Button
                  sx={{ float: "left" }}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => handleArrow("back")}
                >
                  <Typography
                    variant="button"
                    sx={{ textTransform: "capitalize" }}
                  >
                    Back
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6} sx={active === 0 ? { display: "none" } : null}>
                <Typography
                  variant="p"
                  sx={{
                    paddingTop: "7px",
                    float: "left",
                  }}
                >
                  {active + 1} / {questions.length}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "21px" }}>
                <Box sx={{ mb: "24px" }}>
                  <Typography gutterBottom variant="subHeader20" align="center" sx={{color: "#1D2D5E"}}>
                    {questions[active].question}
                  </Typography>
                  <Typography gutterBottom variant="p" align="center">
                    {questions[active].description}
                  </Typography>
                </Box>
                <Grow in={animate}>
                  <Grid container spacing={2}>
                    {questions[active].values.map((value) => (
                      <Grid key={value.id} item xs={12} md={6}>
                        <Button
                          fullWidth
                          onClick={() => {handleSubmit(value, active)}}
                          size="large"
                          id={value.id}
                          variant={
                            value.id !== selected[active]
                              ? "outlined"
                              : "contained"
                          }
                          style={
                            value.id !== selected[active]
                              ? {
                                  borderRadius: "80px",
                                  boxShadow:
                                    "0px 4px 8px rgba(37, 37, 37, 0.08)",
                                  backgroundColor: "white",
                                  borderColor: "transparent",
                                }
                              : {
                                  backgroundColor: "#1D2D5E",
                                  borderRadius: "80px",
                                }
                          }
                        >
                          <Typography
                            variant="p"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {value.value}
                          </Typography>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Grow>
              </Grid>
            </Grid>
          </Container>
        </>
  );
};

export default Quest;
