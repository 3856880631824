import Questionnaire from "./Questionnaire";
import MainLayout from "./FullScreenLayout";
const routes = [
    {
        path: "/",
        element: <MainLayout />,
        children: [
          { path: "/", element: <Questionnaire /> }
        ],
    }
]

export default routes;